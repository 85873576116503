<template>
    <div class="yz-ab-wap">
        <erji-carousel></erji-carousel>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 伟东云教育集团 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                    全球互联网教育平台运营商，致力于构建终身教育生态系统
                </p>
            </div>
        </div>
        <div class="ab-con">
            <div class="ab-img"><img src="../../../assets/images/wd-pic.png"></div>
            <div class="ab-item">
                <p>伟东云教育，创立于2012年，以全球互联网教育平台运营商为定位，面向各国政府、企业、院校、机构提供优质教育资源与服务，通过搭建跨系统、跨平台的互联网教育中台，打造“一体两翼”的全球化教育格局，构建全球终身教育生态。</p>
                <p>作为联合国教科文组织战略合作伙伴，伟东云教育先后承办两届四次国际教育信息化大会；作为上海合作组织战略合作伙伴，伟东云教育以教育合作落实“上海精神”，助推人文交流；作为中国教育部合作伙伴，伟东云教育推进“一带一路”教育行动，促进全球教育资源共建共享。</p>
                <p>目前，伟东云教育正按照“一主两副”原则，与青岛国际职教城协同发展，全力构筑职业教育新平台，业务已累计覆盖全国25省、169个市区县，在全球16个国家及地区实现布局，服务众多世界500强企业。</p>
            </div>
        </div>
         <div style="clear:both"></div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
   .yz-ab-wap{
       width: 100%;
       height: auto;
       .ab-text{
           margin-top:40px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
        .ab-con{
            width: 1200px;
            margin:0 auto;
            clear: both;
            overflow: hidden;
            padding:50px 0px 50px 0px;
               .ab-img{
                   width:40%;
                   height: 300px;
                   float: left;
                     img{
                         width:100%;
                         height: 100%;
                     }
               }
               .ab-item{
                    width:58%;
                    height: 300px;
                    float: right;
                    p{
                        text-align: left;
                        text-indent: 30px;
                        margin-top:30px;
                        color:#000;
                        line-height: 25px;
                    }
               }
           }
   }
</style>
